// @flow
import type {
  GroupClassType,
  GroupClassTypeSielBleu,
  GroupClassTypePartner,
  GroupClassTypeSubsidized,
  GroupClassRequestTypeInitiated,
  GroupClassRequestTypeAccepted,
  GroupClassRequestTypeRefused,
  RegisteredPayload,
  PaymentCondition,
  ShortGroupClassPayload,
  GroupClassTypeSubsidizedNoCost,
  GroupClassTypeSubsidizedWithCost,
  GroupClassTypeIntermediateNoCost,
  GroupClassTypeIntermediateWithCost,
  GroupClassTypeBeneficiary,
} from '@groupClass/types';
import type { Option } from '@core/types';

import { PAYMENT_FREQUENCY_ANNUAL, PAYMENT_FREQUENCY_PERIOD } from '@paymentConditionType/constants';

export const GROUP_CLASS_TYPE_SIEL_BLEU: GroupClassTypeSielBleu = 'SIEL BLEU';
export const GROUP_CLASS_TYPE_PARTNER: GroupClassTypePartner = 'PARTNER';
export const GROUP_CLASS_TYPE_SUBSIDIZED: GroupClassTypeSubsidized = 'SUBSIDIZED';
export const GROUP_CLASS_TYPE_SUBSIDIZED_NO_COST: GroupClassTypeSubsidizedNoCost = 'subsidized_no_cost';
export const GROUP_CLASS_TYPE_SUBSIDIZED_WITH_COST: GroupClassTypeSubsidizedWithCost = 'subsidized_with_cost';
export const GROUP_CLASS_TYPE_INTERMEDIATE_NO_COST: GroupClassTypeIntermediateNoCost = 'intermediate_no_cost';
export const GROUP_CLASS_TYPE_INTERMEDIATE_WITH_COST: GroupClassTypeIntermediateWithCost = 'intermediate_with_cost';
export const GROUP_CLASS_TYPE_BENEFICIARY: GroupClassTypeBeneficiary = 'beneficiary';

export const GROUP_CLASS_TYPES: GroupClassType[] = [
  GROUP_CLASS_TYPE_SIEL_BLEU,
  GROUP_CLASS_TYPE_PARTNER,
  GROUP_CLASS_TYPE_SUBSIDIZED,
  GROUP_CLASS_TYPE_SUBSIDIZED_NO_COST,
  GROUP_CLASS_TYPE_SUBSIDIZED_WITH_COST,
  GROUP_CLASS_TYPE_INTERMEDIATE_NO_COST,
  GROUP_CLASS_TYPE_INTERMEDIATE_WITH_COST,
  GROUP_CLASS_TYPE_BENEFICIARY,
];

export const ADDITIONAL_INFORMATION_LIMIT: number = 150;
export const DESCRIPTION_LENGTH_LIMIT: number = 1000;
export const COMMENT_LENGTH_LIMIT: number = 200;
export const EMAIL_LENGTH_LIMIT: number = 78;
export const EMAIL_MESSAGE_LENGTH_LIMIT: number = 2000;
export const EXCEPT_DATES_LENGTH_LIMIT: number = 150;
export const REGISTRATION_TERMS_LENGTH_LIMIT: number = 150;
export const SMS_LENGTH_LIMIT: number = 200;
export const TEXTAREA_LENGTH_LIMIT: number = 1500;

export const GROUP_CLASS_REQUEST_INITIATED: GroupClassRequestTypeInitiated = 'initiated';
export const GROUP_CLASS_REQUEST_ACCEPTED: GroupClassRequestTypeAccepted = 'accepted';
export const GROUP_CLASS_REQUEST_REFUSED: GroupClassRequestTypeRefused = 'refused';

export const GROUP_CLASS_PAYMENT_CONDITION: string[] = [
  'paymentCondition.couple',
  'paymentCondition.multiplecourses',
  'paymentCondition.familysb',
  'paymentCondition.annualfees',
  'paymentCondition.subsidy',
  'paymentCondition.other',
];

export const GROUP_CLASS_PEOPLE_FORM_COUNTRY: Option[] = [
  { label: 'countries.fr', value: 'FR' },
  { label: 'countries.es', value: 'ES' },
];

export const GROUP_CLASS_PAYMENT_FREQUENCY: Option[] = [
  { label: 'groupClasses.groupClass.peoples.form.paymentFrequencyOnce', value: PAYMENT_FREQUENCY_ANNUAL },
  { label: 'groupClasses.groupClass.peoples.form.paymentFrequencyPeriod', value: PAYMENT_FREQUENCY_PERIOD },
];

export const GROUP_CLASS_PEOPLE_GENDER: Option[] = [
  { label: 'gender.male', value: 'male' },
  { label: 'gender.female', value: 'female' },
];

export const OPTIONS_DATE_FORMAT = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const INITIAL_GROUP_CLASS_REGISTERED: RegisteredPayload = {
  paymentInfos: {
    multipleCoursesDiscountLessGroupClass: 0,
    multipleCoursesDiscountableGroupClassCount: 0,
  },
  annualFeesRequested: false,
  groupClassesRegisteredTo: ([]: Array<ShortGroupClassPayload>),
  paymentComment: '',
  paymentFrequency: PAYMENT_FREQUENCY_PERIOD,
  paymentConditions: ([]: Array<PaymentCondition>),
};

export const REFERENSIEL_SESSIONS_URL: string = 'https://referensiel.com/sessions';

export const GOOGLE_MAPS_API_KEY: string = (process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'no-api-key');

export const MAX_UPLOAD_FILES = 3;

export const MAX_UPLOAD_FILE_SIZE = 104857600;

export const ZIPCODE_REGEX = '[0-9]{5}';

export const CITY_REGEX = '[- A-Za-zÀ-ÿ0-9]{3,}';

export const FRANCE = 'FR';
export const SPAIN = 'es-ES';
export const SPAIN_INDICATIVE_PRICE = 5;
