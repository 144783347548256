// @flow
import { WEB_PATHS } from '@app/constants/paths';
import * as appTabsConstants from '@app/constants/tabs';
import RolesHelpers from '@user/helpers/RolesHelpers';
import type { RolesTypeValue } from '@beneficiary/types';
import type { Tab } from '@shared/Navigation/TabElement/types';

const { PAYMENTS, GROUP_SEND, PARTICIPANTS, GROUP_CLASS } = WEB_PATHS;

export const getGroupClassesTabs = (roles: Array<RolesTypeValue>): Tab[] => {
  const tabs = [];

  let tabTranslationKey = 'navigation.findGroupClass';
  if (RolesHelpers.hasRoleAdmin(roles) || RolesHelpers.hasRoleManager(roles) || RolesHelpers.hasRoleCoach(roles)) {
    tabTranslationKey = 'navigation.myGroupClasses';
  }

  tabs.push({
    ...appTabsConstants.GROUP_CLASSES_TAB,
    translationKey: tabTranslationKey,
  });

  if (RolesHelpers.hasRoleBeneficiary(roles)) {
    tabs.unshift(appTabsConstants.BENEFICIARY_TAB);
    tabs.push(appTabsConstants.SHARED_SESSIONS_TAB);
    tabs.push(appTabsConstants.ONLINE_COURSES_TAB);
  }

  return tabs;
};

const groupClassInformationTab = (id: number): Tab => ({
  name: 'infos',
  translationKey: 'groupClasses.groupClass.navigation.infos',
  isActive: false,
  link: GROUP_CLASS.replace(':classId', String(id)),
});

const groupClassPeopleTab = (id: number, requestsNumber: number): Tab => ({
  name: 'peoples',
  translationKey: 'user.participants',
  isActive: false,
  link: PARTICIPANTS.replace(':classId', String(id)),
  badge: requestsNumber,
});

const groupClassPaymentsTab = (id: number): Tab => ({
  name: 'payments',
  translationKey: 'groupClasses.groupClass.navigation.payments',
  isActive: false,
  link: PAYMENTS.replace(':classId', String(id)),
});

const groupClassSendingGroupTab = (id: number): Tab => ({
  name: 'group-send',
  translationKey: 'notification.send.toGroup',
  isActive: false,
  link: GROUP_SEND.replace(':classId', String(id)),
});

export const groupClassDetailedTabs = (id: number, requestsNumber: number): Tab[] => ([
  groupClassInformationTab(id),
  groupClassPeopleTab(id, requestsNumber),
  groupClassPaymentsTab(id),
  groupClassSendingGroupTab(id),
]);
